
import moment from 'moment';
import { defineComponent, reactive, toRaw } from 'vue';
import { useI18n } from 'vue-i18n';
import { localStorage } from '@/utils/local-storage';
import { InfoCircleOutlined } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import { useForm } from 'ant-design-vue/es/form';
import { message } from 'ant-design-vue';
import { ResponseBody } from '@/api/typing';
import { saveBasicFormData, BasicFormResponse } from '@/api/form/basic-form';

const LOCAL_SAVE_BASIC_FORM_DATA_KEY = 'LOCAL_SAVE_BASIC_FORM_DATA';

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const state = reactive({
      submitting: false,
    });
    // check local save
    let formData: Record<string, any> = {};
    const localFormData = localStorage.get(LOCAL_SAVE_BASIC_FORM_DATA_KEY);
    if (localFormData !== undefined) {
      formData = JSON.parse(localFormData);
      if (formData.date) {
        formData.date = [moment(formData.date[0]), moment(formData.date[1])];
      }
    }

    const modelRef = reactive({
      title: undefined,
      date: [],
      goal: undefined,
      standard: undefined,
      client: undefined,
      invites: undefined,
      weight: null,
      public: '1',
      publicUsers: [],
      ...formData,
    });
    const rulesRef = reactive({
      title: [{ required: true, message: () => t('form.basicform.title.required') }],
      date: [{ required: true, message: () => t('form.basicform.date.required') }],
      goal: [{ required: true, message: () => t('form.basicform.goal.required') }],
      standard: [{ required: true, message: () => t('form.basicform.standard.required') }],
    });

    const { resetFields, validate, validateInfos } = useForm(modelRef, rulesRef);
    const handleSubmit = (e: Event): void => {
      e.preventDefault();
      state.submitting = true;
      validate()
        .then(() => {
          saveBasicFormData({
            title: modelRef.title,
            date: [
              moment(modelRef.date[0]).format('YYYY-MM-DD hh:mm:ss'),
              moment(modelRef.date[1]).format('YYYY-MM-DD hh:mm:ss'),
            ],
            goal: modelRef.goal,
            standard: modelRef.standard,
          }).then((res: ResponseBody<BasicFormResponse>) => {
            state.submitting = false;
            message.success(t(res.message));
            // submit form succeed, remove save form data
            localStorage.remove(LOCAL_SAVE_BASIC_FORM_DATA_KEY);
          });
        })
        .catch(err => {
          console.error('catch::validateInfos', err, toRaw(validateInfos));
          state.submitting = false;
        });
    };

    const saveFormData = (): void => {
      const formData = toRaw(modelRef);
      localStorage.set(LOCAL_SAVE_BASIC_FORM_DATA_KEY, JSON.stringify(formData));
      message.info(t('form.basicform.form.save'));
    };
    return {
      t,
      route,
      state,

      // form
      labelCol: { lg: { span: 7 }, sm: { span: 7 } },
      wrapperCol: { lg: { span: 10 }, sm: { span: 17 } },
      modelRef,
      handleSubmit,
      validateInfos,
      resetFields,
      saveFormData,
    };
  },
  components: {
    InfoCircleOutlined,
  },
});
