import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "optional" }
const _hoisted_2 = { class: "optional" }
const _hoisted_3 = { class: "optional" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_info_circle_outlined = _resolveComponent("info-circle-outlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_page_container = _resolveComponent("page-container")!

  return (_openBlock(), _createBlock(_component_page_container, {
    title: _ctx.t(`${_ctx.route.meta.title}`)
  }, {
    content: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.t('form.basicform.basic.description')), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_card, { bordered: false }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            "hide-required-mark": "",
            "label-col": _ctx.labelCol,
            "wrapper-col": _ctx.wrapperCol
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, _mergeProps({
                label: _ctx.t('form.basicform.title.label')
              }, _ctx.validateInfos.title), {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.modelRef.title,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelRef.title) = $event)),
                    placeholder: _ctx.t('form.basicform.title.placeholder')
                  }, null, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 16, ["label"]),
              _createVNode(_component_a_form_item, _mergeProps({
                label: _ctx.t('form.basicform.date.label')
              }, _ctx.validateInfos.date), {
                default: _withCtx(() => [
                  _createVNode(_component_a_range_picker, {
                    value: _ctx.modelRef.date,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelRef.date) = $event)),
                    placeholder: [
              _ctx.t('form.basicform.placeholder.start'),
              _ctx.t('form.basicform.placeholder.end'),
            ],
                    style: { width: '100%' }
                  }, null, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 16, ["label"]),
              _createVNode(_component_a_form_item, _mergeProps({
                label: _ctx.t('form.basicform.goal.label')
              }, _ctx.validateInfos.goal), {
                default: _withCtx(() => [
                  _createVNode(_component_a_textarea, {
                    value: _ctx.modelRef.goal,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelRef.goal) = $event)),
                    placeholder: _ctx.t('form.basicform.goal.placeholder'),
                    style: { minHeight: 32 },
                    rows: 4
                  }, null, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 16, ["label"]),
              _createVNode(_component_a_form_item, _mergeProps({
                label: _ctx.t('form.basicform.standard.label')
              }, _ctx.validateInfos.standard), {
                default: _withCtx(() => [
                  _createVNode(_component_a_textarea, {
                    value: _ctx.modelRef.standard,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modelRef.standard) = $event)),
                    placeholder: _ctx.t('form.basicform.standard.placeholder'),
                    style: { minHeight: 32 },
                    rows: 4
                  }, null, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 16, ["label"]),
              _createVNode(_component_a_form_item, _normalizeProps(_guardReactiveProps(_ctx.validateInfos.client)), {
                label: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.t('form.basicform.client.label')), 1),
                  _createElementVNode("em", _hoisted_1, [
                    _createTextVNode(_toDisplayString(_ctx.t('form.basicform.form.optional')) + " ", 1),
                    _createVNode(_component_a_tooltip, {
                      title: _ctx.t('form.basicform.label.tooltip')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_info_circle_outlined)
                      ]),
                      _: 1
                    }, 8, ["title"])
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.modelRef.client,
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modelRef.client) = $event)),
                    placeholder: _ctx.t('form.basicform.client.placeholder')
                  }, null, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 16),
              _createVNode(_component_a_form_item, _normalizeProps(_guardReactiveProps(_ctx.validateInfos.invites)), {
                label: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.t('form.basicform.invites.label')), 1),
                  _createElementVNode("em", _hoisted_2, _toDisplayString(_ctx.t('form.basicform.form.optional')), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.modelRef.invites,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modelRef.invites) = $event)),
                    placeholder: _ctx.t('form.basicform.invites.placeholder')
                  }, null, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 16),
              _createVNode(_component_a_form_item, _normalizeProps(_guardReactiveProps(_ctx.validateInfos.weight)), {
                label: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.t('form.basicform.weight.label')), 1),
                  _createElementVNode("em", _hoisted_3, _toDisplayString(_ctx.t('form.basicform.form.optional')), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    modelValue: _ctx.modelRef.weight,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modelRef.weight) = $event)),
                    placeholder: _ctx.t('form.basicform.weight.placeholder')
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }, 16),
              _createVNode(_component_a_form_item, _mergeProps({
                label: _ctx.t('form.basicform.public.label')
              }, _ctx.validateInfos.public), {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_a_radio_group, {
                      value: _ctx.modelRef.public,
                      "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.modelRef.public) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_radio, { value: "1" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('form.basicform.radio.public')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_radio, { value: "2" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('form.basicform.radio.partially-public')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_radio, { value: "3" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('form.basicform.radio.private')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"]),
                    _createVNode(_component_a_form_item, _mergeProps({ style: { marginBottom: 0 } }, _ctx.validateInfos.publicUsers), {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          style: _normalizeStyle({
                  margin: '8px 0',
                  display: _ctx.modelRef.public === '2' ? 'block' : 'none',
                })
                        }, [
                          _createVNode(_component_a_select, {
                            value: _ctx.modelRef.publicUsers,
                            "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.modelRef.publicUsers) = $event)),
                            mode: "multiple",
                            placeholder: _ctx.t('form.basicform.publicUsers.placeholder')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select_option, { value: "1" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('form.basicform.option.A')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "2" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('form.basicform.option.B')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "3" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('form.basicform.option.C')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder"]),
                          _createElementVNode("span", null, _toDisplayString(_ctx.t('form.basicform.label.help')), 1)
                        ], 4)
                      ]),
                      _: 1
                    }, 16)
                  ])
                ]),
                _: 1
              }, 16, ["label"]),
              _createVNode(_component_a_form_item, _mergeProps({
            wrapperCol: {
              xs: { span: 24, offset: 0 },
              sm: { span: 10, offset: 7 },
            },
          }, { style: { marginTop: 32 } }), {
                default: _withCtx(() => [
                  _createVNode(_component_a_space, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        loading: _ctx.state.submitting,
                        onClick: _ctx.handleSubmit
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('form.basicform.form.submit')), 1)
                        ]),
                        _: 1
                      }, 8, ["loading", "onClick"]),
                      _createVNode(_component_a_button, { onClick: _ctx.saveFormData }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('form.basicform.form.save')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 16)
            ]),
            _: 1
          }, 8, ["label-col", "wrapper-col"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title"]))
}